import { memo } from 'react'

import {
  Group,
  Progress,
  ScrollArea,
  Stack,
  Table,
  Text,
  Title,
  Tooltip,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

import {
  crypto_value_formatter,
  local_date_time_formatter,
  usdLargeNumberFormatter,
} from '../../helpers/formatters'
import { useGetPortfolioLedger } from '../../queries/portfolio'
import { ActionHideShow } from '../ActionHideShow'
import { CryptoLookupTxLink } from '../CryptoLookupTxLink'
import { TokenAndSymbolRender } from '../TokenRender'

import classes from '../PortfolioTable/PortfolioTable.module.css'

// eslint-disable-next-line max-lines-per-function
export const PortfolioLedger = memo(function PortfolioLedger() {
  const { progress, total, ledger, isLoading, isConnected } =
    useGetPortfolioLedger()
  const [opened, disclosure_handlers] = useDisclosure(false)

  if (!isConnected) return null

  return (
    <Stack gap="lg">
      <Group>
        <Title order={2}>Portfolio Ledger</Title>
        <ActionHideShow
          opened={opened}
          toggle={disclosure_handlers.toggle}
          item_noun="ledger records"
        />
      </Group>
      {opened && (
        <Stack>
          {progress < total && <Progress value={(progress / total) * 100} />}
          {isLoading || (
            <ScrollArea scrollbars="x" w="100%" type="auto" pb="md">
              <Table classNames={classes} w="fit-content" miw="700">
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Date</Table.Th>
                    <Table.Th>Token</Table.Th>
                    <Table.Th>Token Change</Table.Th>
                    <Table.Th>USDC Value</Table.Th>
                    <Table.Th>Action Type</Table.Th>
                    <Table.Th>Tx</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {ledger.map((record) => (
                    <Table.Tr key={record.id}>
                      <Table.Td>
                        <Text opacity={0.7} size="sm">
                          {local_date_time_formatter(record.timestamp)}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <TokenAndSymbolRender symbol={record.symbol} />
                      </Table.Td>
                      <Table.Td>
                        <Tooltip label={record.change_value} position="right">
                          <Text span>
                            {crypto_value_formatter(record.change_value, {
                              maximumFractionDigits: 4,
                            })}
                          </Text>
                        </Tooltip>
                      </Table.Td>
                      <Table.Td>
                        {usdLargeNumberFormatter(
                          Math.abs(record.balance_change_usdc_value),
                          {
                            missing_string: 'n/a',
                            maximumFractionDigits: 2,
                          },
                        )}
                      </Table.Td>
                      <Table.Td>
                        <Text opacity={0.7} size="sm">
                          {record.type}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <CryptoLookupTxLink
                          address={record.tx}
                          opacity={0.7}
                          size="sm"
                          c="inherit"
                        />
                      </Table.Td>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>
            </ScrollArea>
          )}
        </Stack>
      )}
    </Stack>
  )
})
