import { initGraphQLTada } from 'gql.tada'
import { GraphQLClient } from 'graphql-request'

import type { introspection } from '../prices_schema-graphql-env'

import { create_graphql_call, create_graphql_multi_call } from './utilities.ts'

export const graphql = initGraphQLTada<{
  introspection: introspection
  scalars: {
    BigDecimal: string
    BigInt: string
    ID: string
  }
}>()

export const server_client = new GraphQLClient(
  import.meta.env.VITE_SUBGRAPH_KEY
    ? `https://subgraph.satsuma-prod.com/${import.meta.env.VITE_SUBGRAPH_KEY}/fission-labs--131480/prices-${import.meta.env.VITE_SUBGRAPH_ENV}/api`
    : 'http://localhost/graphql',
)
export const graphql_call = create_graphql_call(server_client)

export const graphql_multi_call = create_graphql_multi_call(server_client)
