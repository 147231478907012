export function always_default_data<T, R>(
  default_data: T,
  results: R & { data: T | undefined },
) {
  return {
    ...results,
    data: results.data ?? default_data,
  }
}

type MergeMutationType = {
  isPending: boolean
  isSuccess: boolean
  isError: boolean
  error: null | Error
}

export const merge_mutation_not_ready: MergeMutationType & { isReady: false } =
  {
    isReady: false,
    isPending: false,
    isSuccess: false,
    isError: false,
    error: null,
  } as const

export function merge_mutations<T extends MergeMutationType>(mutations: T[]) {
  return mutations.reduce<MergeMutationType & { isReady: true }>(
    (acc, mut) => ({
      isReady: true,
      isPending: acc.isPending || mut.isPending,
      isSuccess: acc.isSuccess && mut.isSuccess,
      isError: acc.isError || mut.isError,
      error: acc.error ?? mut.error,
    }),
    {
      isReady: true,
      isPending: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
  )
}

type MergeQueryType = {
  isSuccess: boolean
  isLoading: boolean
  isError: boolean
  error: null | Error
}

export function merge_queries<T extends MergeQueryType>(queries: T[]) {
  return queries.reduce<MergeQueryType>(
    (acc, mut) => ({
      isSuccess: acc.isSuccess && mut.isSuccess,
      isLoading: acc.isLoading || mut.isLoading,
      isError: acc.isError || mut.isError,
      error: acc.error ?? mut.error,
    }),
    {
      isSuccess: true,
      isLoading: false,
      isError: false,
      error: null,
    },
  )
}
