import { useCallback } from 'react'

import { Group } from '@mantine/core'
import { createFileRoute, Navigate, useNavigate } from '@tanstack/react-router'

import {
  email_confirm_search_schema,
  EmailConfirm,
} from '@repo/common/components/EmailConfirm'

export const Route = createFileRoute('/_appish/confirm')({
  component: ConfirmPage,
  validateSearch: email_confirm_search_schema,
})

///confirm?uuid=3d644032-16e8-478c-9cd8-e768f584ba38&token=914a1f79-3d35-4329-b246-2e5925092711&type=reset

function ConfirmPage() {
  const { token, uuid } = Route.useSearch()
  const navigate = useNavigate()

  const onSuccess = useCallback(() => {
    void navigate({ to: '/portfolio', replace: true })
  }, [navigate])

  if (uuid == null) {
    return <Navigate to="/" replace />
  }

  return (
    <Group
      gap="xl"
      wrap="nowrap"
      pl={{ base: 'sm', sm: '0' }}
      pr={{ base: 'sm', sm: 'xl' }}
      align="top"
      maw="400"
      mx="auto"
    >
      <EmailConfirm token={token} uuid={uuid} onSuccess={onSuccess} />
    </Group>
  )
}
