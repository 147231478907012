import { memo } from 'react'

import {
  Box,
  Center,
  Group,
  Progress,
  ScrollArea,
  Stack,
  Table,
  Text,
} from '@mantine/core'

import { TokenRender } from '@repo/common/components/TokenRender'

import {
  countFormatter,
  percentage_formatter,
  usdFormatter,
} from '../../helpers/formatters'
import { useListPortfolio } from '../../queries/portfolio'
import type { TokenSymbol } from '../../queries/products'
import { ConnectWalletButton } from '../ConnectWalletButton'
import { LinkButton } from '../LinkButton'
import { LiquidityPosition } from '../LiquidityPosition'

import classes from './PortfolioTable.module.css'

// eslint-disable-next-line max-lines-per-function
export const PortfolioTable = memo(function PortfolioTable({}) {
  const {
    entries,
    positions,
    totals,
    progress,
    total,
    isLoading,
    isConnected,
  } = useListPortfolio()

  if (!isConnected) {
    return (
      <Center h="100%">
        <ConnectWalletButton message="Connect Wallet to see holdings" />
      </Center>
    )
  }

  return (
    <Stack maw="100%">
      <ScrollArea scrollbars="x" w="100%" type="auto" pb="md">
        <Table classNames={classes}>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Token</Table.Th>
              <Table.Th>Current Value</Table.Th>
              <Table.Th>Tokens</Table.Th>
              <Table.Th>Price per Token</Table.Th>
              <Table.Th>Average Price Paid</Table.Th>
              <Table.Th>Gain/Loss $</Table.Th>
              <Table.Th>Gain/Loss %</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {progress < total && (
              <Table.Tr>
                <Table.Td colSpan={8} py="sm">
                  <Progress value={(progress / total) * 100} />
                </Table.Td>
              </Table.Tr>
            )}
            {entries.map(
              ({
                symbol,
                image_slug,
                type,
                usdc_value,
                tokens,
                price,
                average_paid,
                gain_usdc,
                gain_percent,
              }) => (
                <Table.Tr key={symbol} h="60px">
                  <Table.Td>
                    <Group wrap="nowrap" gap="0.75em">
                      <TokenRender
                        image_slug={image_slug}
                        type={type}
                        size="var(--mantine-font-size-xl2)"
                      />
                      <Text>{symbol}</Text>
                    </Group>
                  </Table.Td>
                  <Table.Td data-testid={symbol}>
                    {usdFormatter(usdc_value)}
                  </Table.Td>
                  <Table.Td>
                    {countFormatter(tokens, {
                      maximumFractionDigits: 4,
                      compact: false,
                    })}
                  </Table.Td>
                  <Table.Td>{usdFormatter(price)}</Table.Td>
                  <Table.Td>{usdFormatter(average_paid)}</Table.Td>
                  <Table.Td>{usdFormatter(gain_usdc)}</Table.Td>
                  <Table.Td>
                    {gain_usdc == 0
                      ? ''
                      : gain_percent == null
                        ? 'all'
                        : percentage_formatter(gain_percent)}
                  </Table.Td>
                  <Table.Td>
                    <PortfolioActions symbol={symbol} />
                  </Table.Td>
                </Table.Tr>
              ),
            )}
          </Table.Tbody>
          {isLoading || (
            <Table.Tfoot>
              <Table.Tr>
                <Table.Th>Totals</Table.Th>
                <Table.Td data-testid="total">
                  {usdFormatter(totals.usdc_value)}
                </Table.Td>
                <Table.Td></Table.Td>
                <Table.Td></Table.Td>
                <Table.Td></Table.Td>
                <Table.Td></Table.Td>
                <Table.Td></Table.Td>
                <Table.Td></Table.Td>
              </Table.Tr>
            </Table.Tfoot>
          )}
        </Table>
      </ScrollArea>
      {positions?.length > 0 && (
        <ScrollArea scrollbars="x" type="auto" pb="md">
          <Stack p="lg" miw="max-content">
            {positions?.map((p) => (
              <LiquidityPosition position={p} key={p.id} />
            ))}
          </Stack>
        </ScrollArea>
      )}
    </Stack>
  )
})

function PortfolioActions({ symbol }: { symbol: TokenSymbol }) {
  if (symbol === 'TECH') {
    return (
      <Group justify="flex-end" gap="0">
        <LinkButton
          to="/investments/$symbol"
          params={{ symbol }}
          size="sm"
          variant="subtle"
          miw="120px"
        >
          Invest
        </LinkButton>
        <Box w="120px" />
        <LinkButton
          to="/investments/$symbol"
          params={{ symbol }}
          size="sm"
          variant="subtle"
          miw="120px"
        >
          Details
        </LinkButton>
      </Group>
    )
  }
  if (symbol === 'TVLT') {
    return (
      <Group justify="flex-end" gap="0">
        <LinkButton
          to="/vault/$symbol"
          params={{ symbol }}
          size="sm"
          variant="subtle"
          miw="120px"
        >
          Deposit
        </LinkButton>
        <LinkButton
          to="/vault/$symbol"
          params={{ symbol }}
          size="sm"
          variant="subtle"
          miw="120px"
        >
          Redeem
        </LinkButton>
        <LinkButton
          to="/vault/$symbol"
          params={{ symbol }}
          size="sm"
          variant="subtle"
          miw="120px"
        >
          Details
        </LinkButton>
      </Group>
    )
  }
  return null
}
