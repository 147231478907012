import { createQueryKeyStore } from '@lukemorales/query-key-factory'
import { useQuery } from '@tanstack/react-query'
import * as _ from 'lodash-es'

import { graphql_call } from '@repo/common/queries/helpers/server_query'

import {
  query_list_crypto_contracts,
  query_list_reward_wallet_addresses,
} from './crypto_contracts_graphql'

export const keys = createQueryKeyStore({
  crypto_contracts: {
    all: null,
    reward_wallets: null,
  },
})

export function useListCryptoContracts() {
  const results = useQuery({
    queryKey: keys.crypto_contracts.all.queryKey,
    queryFn: () => graphql_call({ query: query_list_crypto_contracts }),
    staleTime: Infinity,
    gcTime: Infinity,
  })

  const contracts = results.data?.contracts ?? []

  const contract_find_by = ({ name }: { name: string }) =>
    contracts.find((f) => f.name.toLowerCase() == name.toLowerCase())

  return {
    ...results,
    data: {
      contracts,
      contract_base_url: _.trimEnd(results.data?.contract_base_url ?? '', '/'),
    },
    contract_find_by,
  }
}

export type CryptoContractNames =
  | 'DEX'
  | 'POSITION_MANAGER'
  | 'QUOTERV2'
  | 'PERMIT2'
  | 'UNIVERSAL_ROUTER'

export function useGetContractAddress(name: CryptoContractNames) {
  const { contract_find_by } = useListCryptoContracts()
  return contract_find_by({ name })?.address
}

export function useListRewardWalletAddresses() {
  return useQuery<CryptoAddress[]>({
    queryKey: keys.crypto_contracts.reward_wallets.queryKey,
    queryFn: async () => {
      const data = await graphql_call({
        query: query_list_reward_wallet_addresses,
      })
      return data?.reward_wallets.map(({ address }) => address) ?? []
    },
    staleTime: Infinity,
    gcTime: Infinity,
  })
}
