/* eslint-disable complexity */
import { memo, useCallback } from 'react'

import { Button, Grid, Group, Stack, Text } from '@mantine/core'
import { useAccount } from 'wagmi'

import { get_blockchain_error_message } from '@repo/common/blockchain/get_blockchain_error_message'
import { ConnectWalletButton } from '@repo/common/components/ConnectWalletButton'
import { TokenRender } from '@repo/common/components/TokenRender'
import { WalletExecuteButton } from '@repo/common/components/WalletExecuteButton'
import { countFormatter, usdFormatter } from '@repo/common/helpers/formatters'
import {
  useCheckPermit2,
  useSetupPermit2,
} from '@repo/common/queries/blockchain/useSetupPermit2'
import { useBuyCartVault } from '@repo/common/queries/cart'
import { useGetTokenPrice } from '@repo/common/queries/fission_dex'
import {
  type TokenSymbol,
  useBuyVaultProduct,
} from '@repo/common/queries/products/products_queries'
import { useGetVaultLatestData } from '@repo/common/queries/vaults'

import classes from '../PanelBuyVault/PanelBuyVault.module.css'

export const PanelPaymentVault = memo<{
  vault_id: RubyID
  onCompleted: () => void
  onCancel: () => void
}>(
  // eslint-disable-next-line max-lines-per-function
  function PanelPaymentVault({ onCompleted, onCancel, vault_id }) {
    const { isConnected } = useAccount()
    const vault_buy = useBuyVaultProduct({ id: vault_id })
    const cart = useBuyCartVault()
    const fund_symbol: TokenSymbol = 'TECH'
    const { data: vault_data } = useGetVaultLatestData({ id: vault_id })
    const { data: tech_price } = useGetTokenPrice({ symbol: fund_symbol })

    const deposit_fund_tokens = cart.values.deposit_fund_tokens ?? 0
    const deposit_usdc_tokens = cart.values.deposit_usdc_tokens ?? 0
    const vault_price = Number(vault_data?.price_per_token ?? 0)

    const usdc_permit_query = useCheckPermit2({ symbol: 'USDC' })
    const usdc_setup_permit = useSetupPermit2({ symbol: 'USDC' })

    const fund_permit_query = useCheckPermit2({ symbol: fund_symbol })
    const fund_setup_permit = useSetupPermit2({ symbol: fund_symbol })

    const is_ready: boolean =
      vault_buy.isReady &&
      vault_price != null &&
      deposit_fund_tokens + deposit_usdc_tokens > 0

    const onSubmit = useCallback(() => {
      if (!vault_buy.isReady) return
      vault_buy
        .mutateAsync({
          deposit_fund_tokens,
          deposit_usdc_tokens,
        })
        .then(onCompleted)
        .catch(console.error)
      // product_buy is not stable for memo
    }, [deposit_fund_tokens, deposit_usdc_tokens, onCompleted, vault_buy])

    let error_message: string = ''
    if (vault_buy.isReady && vault_buy.isError) {
      error_message = get_blockchain_error_message(vault_buy.error)
    }

    if (vault_price == null || tech_price == null) return null

    const total_vault =
      (deposit_fund_tokens * tech_price + deposit_usdc_tokens) / vault_price

    let button_content
    if (isConnected == false) {
      button_content = (
        <ConnectWalletButton
          size="lg"
          miw="12em"
          message="Connect Wallet First"
        />
      )
    } else if (usdc_permit_query.data?.permit_enabled == false) {
      button_content = (
        <WalletExecuteButton
          size="lg"
          miw="12em"
          variant="gradient"
          onClick={() => usdc_setup_permit.mutate()}
          loading={usdc_permit_query.isPending || usdc_setup_permit.isPending}
        >
          Enable USDC permissions
        </WalletExecuteButton>
      )
    } else if (fund_permit_query.data?.permit_enabled == false) {
      button_content = (
        <WalletExecuteButton
          size="lg"
          miw="12em"
          variant="gradient"
          onClick={() => fund_setup_permit.mutate()}
          loading={usdc_permit_query.isPending || fund_setup_permit.isPending}
        >
          Enable {fund_symbol} permissions
        </WalletExecuteButton>
      )
    } else {
      button_content = (
        <WalletExecuteButton
          disabled={!is_ready}
          loading={
            vault_buy.isPending ||
            usdc_permit_query.isPending ||
            fund_permit_query.isPending
          }
          variant={is_ready ? 'gradient' : 'outline'}
          size="lg"
          miw="12em"
          onClick={onSubmit}
        >
          Execute Buy
        </WalletExecuteButton>
      )
    }

    return (
      <Grid columns={2} gutter="0" className={classes.root} mt="50">
        {error_message && (
          <Grid.Col span={2} className={classes.row}>
            <Text c="red" pb="lg" role="status">
              Blockchain or Wallet error: {error_message}
            </Text>
          </Grid.Col>
        )}
        <Grid.Col span={1} className={classes.row}>
          <Group>
            <TokenRender image_slug="technology" type="vault" size="50px" />
            <Stack gap="0">
              <Text size="lg" fw="bold" lh="1">
                TECH Vault
              </Text>
              <Text size="sm">TVLT</Text>
            </Stack>
          </Group>
        </Grid.Col>
        <Grid.Col span={1} className={classes.row_value}>
          <Text fw="bold">{usdFormatter(vault_price)}/share</Text>
        </Grid.Col>
        <Grid.Col span={1} className={classes.row}>
          <Text size="sm">Order Type</Text>
        </Grid.Col>
        <Grid.Col span={1} className={classes.row_value}>
          <Text>Market</Text>
        </Grid.Col>
        {deposit_usdc_tokens > 0 && (
          <>
            <Grid.Col span={1} className={classes.row}>
              <Text size="sm">Deposit USDC</Text>
            </Grid.Col>
            <Grid.Col span={1} className={classes.row_value}>
              <Group justify="flex-end" gap="0.5ex">
                <TokenRender
                  type="token"
                  size="var(--mantine-font-size-lg)"
                  image_slug="usdc"
                />
                <Text size="lg">
                  {countFormatter(deposit_usdc_tokens, {
                    maximumFractionDigits: 2,
                    compact: false,
                  })}
                </Text>
              </Group>
            </Grid.Col>
          </>
        )}
        {deposit_fund_tokens > 0 && (
          <>
            <Grid.Col span={1} className={classes.row}>
              <Text size="sm">Deposit {fund_symbol}</Text>
            </Grid.Col>
            <Grid.Col span={1} className={classes.row_value}>
              <Group justify="flex-end" gap="0.5ex">
                <TokenRender
                  type="token"
                  size="var(--mantine-font-size-lg)"
                  image_slug="technology"
                />
                <Text size="lg">
                  {countFormatter(deposit_fund_tokens, {
                    maximumFractionDigits: 4,
                    compact: false,
                  })}
                </Text>
              </Group>
            </Grid.Col>
          </>
        )}
        <Grid.Col span={1} fw="bold" className={classes.row}>
          Total TVLT Tokens
        </Grid.Col>
        <Grid.Col
          span={1}
          fw="bold"
          data-testid="total"
          className={classes.row_value}
        >
          <Group gap="0.5ex">
            <TokenRender
              image_slug="technology"
              type="vault"
              size="var(--mantine-font-size-lg)"
            />
            <Text size="lg">
              {countFormatter(total_vault, {
                maximumFractionDigits: 4,
                compact: false,
              })}
            </Text>
          </Group>
        </Grid.Col>
        <Grid.Col span={2} mt="lg">
          <Group justify="flex-end">
            <Button variant="subtle" onClick={onCancel}>
              Cancel
            </Button>
            {button_content}
          </Group>
        </Grid.Col>
      </Grid>
    )
  },
)
