import { memo, useEffect } from 'react'

import { Button, Grid, Group, NumberInput, Stack, Text } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { useFocusWithin } from '@mantine/hooks'
import { useAccount } from 'wagmi'
import { z } from 'zod'

import { ConnectWalletButton } from '@repo/common/components/ConnectWalletButton'
import { TokenRender } from '@repo/common/components/TokenRender'
import { VaultUndeployedStats } from '@repo/common/components/VaultUndeployedStats'
import { WalletExecuteButton } from '@repo/common/components/WalletExecuteButton'
import { countFormatter, usdFormatter } from '@repo/common/helpers/formatters'
import { useGetWalletBalance } from '@repo/common/queries/blockchain/useGetWalletBalance'
import { useBuyCartVault } from '@repo/common/queries/cart'
import {
  type InSideType,
  useGetTokenPrice,
} from '@repo/common/queries/fission_dex'
import type { TokenSymbol } from '@repo/common/queries/products'
import {
  useGetDepositValues,
  useGetVaultLatestData,
} from '@repo/common/queries/vaults'

// eslint-disable-next-line max-lines-per-function
import classes from './PanelBuyVault.module.css'

const default_reason = 'Enter deposits'

export const PanelBuyVault = memo<{
  vault_id: RubyID
  onCancel: () => void
  onCompleted: () => void
  amount?: number
}>(
  // eslint-disable-next-line max-lines-per-function, complexity
  function PanelBuyVault({ vault_id, onCancel, onCompleted }) {
    const { isConnected } = useAccount()
    const vault_price_result = useGetVaultLatestData({ id: vault_id })
    const fund_symbol: TokenSymbol = 'TECH'
    const { data: tech_price } = useGetTokenPrice({ symbol: fund_symbol })
    const cart = useBuyCartVault()

    const form = useForm({
      initialValues: {
        deposit_usdc_tokens: 1000,
        deposit_fund_tokens: 10,
        in_side: 'USDC',
      } as {
        deposit_usdc_tokens: number | undefined
        deposit_fund_tokens: number | undefined
        in_side: InSideType
      },
      validateInputOnChange: true,
      validate: (values) => zodResolver(get_schema())(values),
    })

    const deposit_fund_focus = useFocusWithin()
    const deposit_usdc_focus = useFocusWithin()

    const { balance: balance_fund } = useGetWalletBalance('TECH')
    const { balance: balance_usdc } = useGetWalletBalance('USDC')

    const updates = useGetDepositValues({ id: vault_id, ...form.getValues() })

    const onSubmit = (
      values: Parameters<Parameters<typeof form.onSubmit>[0]>[0],
    ) => {
      cart.setValues({
        deposit_usdc_tokens: values.deposit_usdc_tokens ?? 0,
        deposit_fund_tokens: values.deposit_fund_tokens ?? 0,
        id: vault_id,
      })
      onCompleted()
    }

    // TODO handle one side positions correctly

    // eslint-disable-next-line complexity
    useEffect(() => {
      if (!updates.enabled) return
      // need because of how it updates value, then side
      if (deposit_usdc_focus.focused && form.getValues().in_side != 'USDC')
        return
      // need because of how it updates value, then side
      if (deposit_fund_focus.focused && form.getValues().in_side != 'TOKEN')
        return

      if (form.getValues().in_side == 'USDC') {
        if (
          form.getValues().deposit_fund_tokens != updates.deposit_fund_tokens
        ) {
          form.setValues({ deposit_fund_tokens: updates.deposit_fund_tokens })
        }
      } else {
        if (
          form.getValues().deposit_usdc_tokens != updates.deposit_usdc_tokens
        ) {
          form.setValues({ deposit_usdc_tokens: updates.deposit_usdc_tokens })
        }
      }
    }, [
      deposit_fund_focus.focused,
      deposit_usdc_focus.focused,
      form,
      updates,
      updates.deposit_fund_tokens,
      updates.deposit_usdc_tokens,
      updates.enabled,
    ])

    const get_schema = () => {
      return z
        .object({
          deposit_fund_tokens: z
            .number({ message: default_reason })
            .nonnegative(default_reason)
            .max(balance_fund, {
              message: `Insufficient ${fund_symbol} balance`,
            }),
          deposit_usdc_tokens: z
            .number({ message: default_reason })
            .nonnegative(default_reason)
            .max(balance_usdc, {
              message: `Insufficient USDC balance`,
            }),
        })
        .refine(
          (values) =>
            values.deposit_fund_tokens + values.deposit_usdc_tokens > 0,
          {
            message: default_reason,
          },
        )
    }

    form.watch('deposit_usdc_tokens', () => {
      if (!deposit_usdc_focus.focused || form.getValues().in_side == 'USDC')
        return
      form.setValues({ in_side: 'USDC' })
    })

    form.watch('deposit_fund_tokens', () => {
      if (!deposit_fund_focus.focused || form.getValues().in_side == 'TOKEN')
        return
      form.setValues({ in_side: 'TOKEN' })
    })

    const enabled = form.isValid()
    const reason =
      get_schema().safeParse(form.getValues()).error?.errors[0]?.message ||
      default_reason

    const vault_price = vault_price_result.data?.price_per_token

    if (vault_price == null || tech_price == null || updates.enabled == false)
      return null

    const total_vault =
      ((form.getValues().deposit_fund_tokens ?? 0) * tech_price +
        (form.getValues().deposit_usdc_tokens ?? 0)) /
      Number(vault_price)

    let button_content
    if (isConnected == false) {
      button_content = (
        <ConnectWalletButton
          size="lg"
          miw="14em"
          message="Connect Wallet First"
        />
      )
    } else {
      button_content = (
        <WalletExecuteButton
          disabled={!enabled}
          variant={enabled ? 'filled' : 'outline'}
          size="lg"
          miw="14em"
          type="submit"
        >
          {enabled ? 'Buy' : reason}
        </WalletExecuteButton>
      )
    }

    return (
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Grid columns={2} gutter="0" className={classes.root}>
          <Grid.Col span={1} className={classes.row}>
            <Group>
              <TokenRender image_slug="technology" type="vault" size="50px" />
              <Stack gap="0">
                <Text size="lg" lh="1">
                  TECH Vault
                </Text>
                <Text size="sm">TVLT</Text>
              </Stack>
            </Group>
          </Grid.Col>
          <Grid.Col span={1} className={classes.row_value}>
            <Text>{usdFormatter(vault_price)}/share</Text>
          </Grid.Col>
          <Grid.Col span={1} className={classes.row}>
            <Text size="sm">Order Type</Text>
          </Grid.Col>
          <Grid.Col span={1} className={classes.row_value}>
            <Text>Market</Text>
          </Grid.Col>
          <Grid.Col span={2} className={classes.row_value}>
            <Text size="sm">
              The Vault optimizes the deposit ratio in order to maximize returns
              on its liquidity position given its current undeployed asset
              balances.
            </Text>
          </Grid.Col>
          <Grid.Col span={2} className={classes.row_value}>
            <VaultUndeployedStats id={vault_id} />
          </Grid.Col>
          <Grid.Col span={1} className={classes.row}>
            <Text size="sm">Deposit </Text>
            <Group justify="flex-end" gap="1ex" mx="xxs">
              <TokenRender
                type="token"
                size="var(--mantine-font-size-lg)"
                image_slug="usdc"
              />
              <Text size="sm">USDC</Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={1} className={classes.row_value}>
            <NumberInput
              ref={deposit_usdc_focus.ref}
              min={0}
              size="lg"
              {...form.getInputProps('deposit_usdc_tokens')}
              name="deposit_usdc_tokens"
              id="deposit_usdc_tokens"
              aria-label="deposit USDC amount"
            />
          </Grid.Col>
          <Grid.Col span={1} offset={1} className={classes.row_value}>
            <Group justify="flex-end" align="baseline" gap="0">
              <Text size="sm" opacity={0.7} data-testid="wallet usdc balance">
                Balance:{' '}
                {countFormatter(balance_usdc, {
                  maximumFractionDigits: 2,
                  compact: false,
                })}
              </Text>
              <Button
                size="compact-sm"
                variant="subtle"
                onClick={() =>
                  form.setValues({
                    deposit_usdc_tokens: balance_usdc,
                    in_side: 'USDC',
                  })
                }
              >
                MAX
              </Button>
            </Group>
          </Grid.Col>

          <Grid.Col span={1} className={classes.row}>
            <Text size="sm">Deposit </Text>
            <Group justify="flex-end" gap="1ex" mx="xxs">
              <TokenRender
                type="token"
                size="var(--mantine-font-size-lg)"
                image_slug="technology"
              />
              <Text size="sm">{fund_symbol}</Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={1} className={classes.row_value}>
            <NumberInput
              ref={deposit_fund_focus.ref}
              min={0}
              size="lg"
              {...form.getInputProps('deposit_fund_tokens')}
              name="deposit_fund_tokens"
              id="deposit_fund_tokens"
              aria-label={`deposit ${fund_symbol} amount`}
            />
          </Grid.Col>
          <Grid.Col span={1} offset={1} className={classes.row_value}>
            <Group justify="flex-end" align="baseline" gap="0">
              <Text size="sm" opacity={0.7} data-testid="wallet token balance">
                Balance:{' '}
                {countFormatter(balance_fund, {
                  maximumFractionDigits: 4,
                  compact: false,
                })}
              </Text>
              <Button
                size="compact-sm"
                variant="subtle"
                onClick={() =>
                  form.setValues({
                    deposit_fund_tokens: balance_fund,
                    in_side: 'TOKEN',
                  })
                }
              >
                MAX
              </Button>
            </Group>
          </Grid.Col>
          <Grid.Col span={1} fw="bold" className={classes.row}>
            <Text size="md">Total Shares</Text>
          </Grid.Col>
          <Grid.Col
            span={1}
            fw="bold"
            data-testid="total"
            className={classes.row_value}
          >
            <Group gap="0.5ex">
              <TokenRender
                image_slug="technology"
                type="vault"
                size="var(--mantine-font-size-lg)"
              />
              <Text>
                {countFormatter(total_vault, {
                  maximumFractionDigits: 4,
                  compact: false,
                })}
              </Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={2} mt="lg">
            <Group justify="flex-end">
              <Button variant="subtle" onClick={onCancel}>
                Cancel
              </Button>
              {button_content}
            </Group>
          </Grid.Col>
        </Grid>
      </form>
    )
  },
)
