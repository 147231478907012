import dayjs from 'dayjs'
import { convertRate, RateInterval } from 'node-irr'

const year = 365

const formatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 2,
})

export function calc_APY_between(
  pv: { balance: number; date: number | string },
  fv: { balance: number; date: number | string },
): string {
  const days = dayjs(fv.date).diff(pv.date, 'day')
  if (days == 0) return 'n/a'

  const daily_rate = get_daily_rate({ pv: pv.balance, fv: fv.balance, days })
  return daily_rate_to_APY(daily_rate)
}

export function apr_to_apy(apr: number): string {
  const apy = (1 + apr / year) ** year - 1
  return formatter.format(apy)
}

export function daily_rate_to_APY(daily_rate: number): string {
  const apy = convertRate(daily_rate, RateInterval.Year)

  return formatter.format(apy)
}

function get_daily_rate({
  pv,
  fv,
  days,
}: {
  pv: number
  fv: number
  days: number
}): number {
  // PV*(1+r)^n = FV
  // r = (FV/PV)^(1/n) - 1

  return (fv / pv) ** (1 / days) - 1
}
