import { type ReactNode, StrictMode, Suspense } from 'react'

import '@mantine/charts/styles.css'
import { MantineProvider } from '@mantine/core'
import '@mantine/core/styles.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import isChromatic from 'chromatic'
import FontFaceObserver from 'fontfaceobserver'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { WagmiProvider } from 'wagmi'

import { make_config } from '@repo/common/blockchain/config'
import { AutoAddWallet } from '@repo/common/core/AutoAddWallet'
import { UserIdentify } from '@repo/common/core/UserIdentify'
import { FlagsProvider } from '@repo/common/hooks/flags'

import { wallet_url } from '@/components/Logo'

import { theme } from './theme'

import './global.css'

const config = make_config({
  appLogoUrl: wallet_url,
  appName: 'Fission™',
})

const queryClient = new QueryClient({})

export { QueryClient }

const font_header = new FontFaceObserver('Lausanne')
font_header
  .load()
  .then(() => {
    document.documentElement.dataset.fontLoaded = 'true'
  })
  .catch(console.warn)

if (
  !window.location.host.includes('127.0.0.1') &&
  !window.location.host.includes('localhost') &&
  !isChromatic()
) {
  // eslint-disable-next-line @cspell/spellchecker
  posthog.init('phc_auUthLHEAdwm8oDxP9xlRUaU7ExOGOBL9xGKdMmBTdL', {
    api_host: 'https://us.i.posthog.com',
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
        email: true,
      },
    },
    loaded(posthog_instance) {
      posthog_instance.register({
        version: __VERSION__,
      })
    },
  })
  if (window.location.host.includes('testnet')) {
    posthog.startSessionRecording({ sampling: true })
  }
} else {
  console.log('[PostHog] PostHog disabled in development mode')
}

// issue: https://fission-xyz.sentry.io/issues/5984392344/events/23fe7f606ed0416daaf00dde6e2be4e2/
// https://stackoverflow.com/questions/65152373/typescript-serialize-bigint-in-json
// @ts-expect-error: polyfill for the JSON.stringify method for BigInt
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface BigInt {
  /** Convert to BigInt to string form in JSON.stringify */
  toJSON: () => string
}
// @ts-expect-error: polyfill for the JSON.stringify method for BigInt
BigInt.prototype.toJSON = function () {
  return this.toString()
}

export function AppContexts({
  queryClient: client = queryClient,
  children,
}: {
  queryClient?: QueryClient
  children: ReactNode
}) {
  return (
    <StrictMode>
      <PostHogProvider client={posthog}>
        <FlagsProvider>
          <Suspense fallback={<>Loading</>}>
            <WagmiProvider config={config}>
              <QueryClientProvider client={client}>
                <AutoAddWallet />
                <UserIdentify />
                <MantineProvider theme={theme} defaultColorScheme="dark">
                  {children}
                </MantineProvider>
                <ReactQueryDevtools />
              </QueryClientProvider>
            </WagmiProvider>
          </Suspense>
        </FlagsProvider>
      </PostHogProvider>
    </StrictMode>
  )
}

// https://github.com/recharts/recharts//issues/3615#issuecomment-1636923358
const error = console.error
// eslint-disable-next-line @typescript-eslint/no-explicit-any
console.error = (...args: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
  if (/defaultProps/.test(args[0])) return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  error(...args)
}
