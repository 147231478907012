import { useMemo } from 'react'

import { skipToken, useQuery, useQueryClient } from '@tanstack/react-query'
import type { TadaDocumentNode } from 'gql.tada'
import * as _ from 'lodash-es'
import { useAccount, usePublicClient } from 'wagmi'

import { graphql_multi_call as graphql_price_call } from '@repo/common/queries/helpers/pricing_query'

import { active_chain } from '../../blockchain/config'

import {
  keys,
  type PortfolioTokenSymbol,
  type TransactionRecord,
  useGetSymbolToDecimalScale,
} from './portfolio_queries'

const make_token_block = (block_number: bigint) => `
  b_${block_number}: tokenPriceDatas(where: {block_number_lte: ${block_number}}, orderBy: block_number, orderDirection: desc, first: 1 ) {
    price_per_token
  }
`

const make_vault_block = (block_number: bigint) => `
  b_${block_number}: vaultPriceDatas(where: {block_number_lte: ${block_number}}, orderBy: block_number, orderDirection: desc, first: 1 ) {
    price_per_token
  }
`

function make_price_block_query({
  new_blocks,
  make_block,
}: {
  new_blocks: bigint[]
  make_block: typeof make_vault_block
}) {
  return `
    query query_get_block_prices {
      ${new_blocks.map(make_block).join('\n')}
    }
  ` as unknown as TadaDocumentNode<
    Record<string, [{ price_per_token: number }]>
  >
}

type BlockPricesType = Record<string, bigint>

// eslint-disable-next-line complexity
export function useGetBlockPrices({
  cleaned_data,
  symbol,
}: {
  cleaned_data: TransactionRecord[] | null
  symbol: PortfolioTokenSymbol
}) {
  const queryClient = useQueryClient()
  const { address } = useAccount()
  const client = usePublicClient({ chainId: active_chain.id })
  const symbol_to_decimal = useGetSymbolToDecimalScale()

  const make_block = symbol == 'TECH' ? make_token_block : make_vault_block

  const blocks = useMemo(
    () =>
      _.filter(
        cleaned_data,
        (r) => r.symbol == symbol && r.balance_change_usdc == null,
      ).map((r) => r.block_number),
    [cleaned_data, symbol],
  )

  const enabled =
    address != null &&
    client != null &&
    symbol_to_decimal != null &&
    cleaned_data != null
  const queryKey = keys.portfolio.block_prices({ symbol }).queryKey

  const last_blocks = queryClient.getQueryData<BlockPricesType>(queryKey) ?? {}

  const new_blocks = _.uniq(
    blocks.filter((b) => last_blocks[b.toString()] == null),
  )

  if (new_blocks.length > 0) {
    void queryClient.invalidateQueries({ queryKey })
  }

  return useQuery<BlockPricesType>({
    queryKey,
    staleTime: Infinity,
    gcTime: Infinity,
    queryFn: !enabled
      ? skipToken
      : async () => {
          const usdc_decimal_scale = symbol_to_decimal['USDC']

          if (new_blocks.length == 0) return last_blocks

          // TODO when FISN has a price
          if (symbol == 'FISN') {
            return _.reduce(
              new_blocks,
              (acc, value) => {
                acc[value.toString()] = BigInt(
                  Math.round(0.05 * Number(usdc_decimal_scale)),
                )
                return acc
              },
              last_blocks,
            )
          }

          const query = make_price_block_query({ new_blocks, make_block })

          const results = await graphql_price_call({ query })

          return _.reduce(
            results,
            // eslint-disable-next-line max-params
            (acc, value: [{ price_per_token: number }], key) => {
              acc[key.split('_')[1]] = BigInt(
                Math.round(
                  value[0].price_per_token * Number(usdc_decimal_scale),
                ),
              )
              return acc
            },
            last_blocks,
          )
        },
  })
}
