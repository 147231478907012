import { memo } from 'react'

import { Box, Group, Stack, Text } from '@mantine/core'

import { TokenRender } from '@repo/common/components/TokenRender'
import { get_pie_color } from '@repo/common/core/pie_colors'
import { percentage_formatter } from '@repo/common/helpers/formatters'
import { useGetVaultComposition } from '@repo/common/queries/vaults'

import { useMobileSwitch } from '@/helpers/useMobileSwitch'

import classes from './Weightings.module.css'

export const Weightings = memo<{ id: RubyID }>(function Weightings({ id }) {
  const { data: vault } = useGetVaultComposition({ id })
  const mobile_switch = useMobileSwitch()

  if (vault == null) return <Text opacity={0.7}>Empty Vault</Text>

  return (
    <Stack>
      <Group className={classes.color_bar_box} gap="0" opacity={0.6}>
        {vault.composition.map((item, index) => (
          <Box
            w={`${item.percentage}%`}
            h="100%"
            key={index}
            title={`${item.name} - ${percentage_formatter(item.percentage)}`}
            bg={get_pie_color(index, { max_colors: vault.composition.length })}
          />
        ))}
      </Group>
      <Group w="100%" justify="space-between">
        {vault.composition.map((item, index) => (
          <Group
            key={index}
            bg="dark.8"
            py="sm"
            pl="sm"
            pr="md"
            miw="250px"
            w={{ base: '100%', sm: 'auto' }}
            className={classes.card}
          >
            <Stack gap="xxs" w="100%">
              <Group
                justify="space-between"
                gap={mobile_switch({ base: 'sm', sm: 'xxl' })}
                w="100%"
              >
                <Group gap="xxs">
                  <TokenRender size="var(--mantine-font-size-xl)" {...item} />
                  <Text>{item.name}</Text>
                </Group>
                <Group gap="xxs">
                  <Box
                    bg={get_pie_color(index, {
                      max_colors: vault.composition.length,
                    })}
                    w="0.5em"
                    h="0.5em"
                    mb="0.25em"
                    opacity={0.6}
                    className={classes.color_box}
                  />
                  <Text>{percentage_formatter(item.percentage)}</Text>
                </Group>
              </Group>
              <Text size="sm" opacity={0.8} pl="2px">
                {item.long_name}
              </Text>
            </Stack>
          </Group>
        ))}
      </Group>
    </Stack>
  )
})
