import { memo } from 'react'

import { Button, Group, Stack, Text } from '@mantine/core'
import { IconPlus } from '@tabler/icons-react'

import { TokenRender } from '@repo/common/components/TokenRender'
import { percentage_formatter } from '@repo/common/helpers/formatters'
import { useGetTokenPrice } from '@repo/common/queries/fission_dex'
import { useFundInfo } from '@repo/common/queries/funds'
import { useGetGovMintRewards } from '@repo/common/queries/gov'

import { ProcessBuy } from '../ProcessBuy'
import { ProcessTrade } from '../ProcessTrade'

const ButtonBody = memo<{ action_word: string }>(function ButtonBody({
  action_word,
}) {
  return (
    <Group gap="xs" wrap="nowrap">
      <Text size="lg">{action_word}</Text>
      <Group gap="0.5ex" align="center" wrap="nowrap">
        <TokenRender
          image_slug="technology"
          type="token"
          size="var(--mantine-font-size-lg)"
        />
        <Text size="md" lh="1">
          TECH
        </Text>
      </Group>
    </Group>
  )
})

// eslint-disable-next-line complexity, max-lines-per-function
export const ProcessInvest = memo<{ fund_id: RubyID }>(function ProcessInvest({
  fund_id,
}) {
  const { data: fund, isLoading } = useFundInfo({ fund_id })
  const { data: fisn_rewards } = useGetGovMintRewards({
    mint_value: 10000,
    fund_id: fund?.id,
  })
  const is_coming_soon = fund?.type == null
  const price_result = useGetTokenPrice({
    symbol: is_coming_soon ? undefined : fund?.symbol,
  })

  if (isLoading) return null
  if (is_coming_soon) {
    return (
      <Button size="lg" disabled>
        Coming Soon
      </Button>
    )
  }

  if (price_result.isLoading) return null

  if (price_result.data != null && fund.price_per_share > price_result.data) {
    return (
      <Stack gap="xs" w="230px" align="center">
        <Text size="md" lh="1">
          Buy discounted from NAV
        </Text>
        <ProcessTrade size="lg" symbol={fund.symbol}>
          <ButtonBody action_word="Buy" />
        </ProcessTrade>
      </Stack>
    )
  }

  return (
    <Stack gap="xs" w="230px" align="center">
      <Group gap="xs" wrap="nowrap">
        <Group c="green.2" fz="lg" gap="0" wrap="nowrap">
          <IconPlus size="1em" />
          <Text fz="lg" lh="1">
            {percentage_formatter(fisn_rewards.percentage)}
          </Text>
        </Group>
        <Group gap="0.5ex" align="center" wrap="nowrap">
          <TokenRender
            image_slug="fisn"
            type="token"
            size="var(--mantine-font-size-lg)"
          />
          <Text size="md" lh="1">
            FISN
          </Text>
        </Group>
        <Text size="md" lh="1">
          Rewards
        </Text>
      </Group>
      <Group>
        <ProcessBuy fund_id={fund.id} size="lg">
          <ButtonBody action_word="Mint" />
        </ProcessBuy>
      </Group>
    </Stack>
  )
})
