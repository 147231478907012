import { memo } from 'react'

import { Group, Text } from '@mantine/core'

import type { TokenSymbol } from '../../queries/products'
import { TokenImage, type TokenImageSlug } from '../TokenImage'
import { TokenPair } from '../TokenPair'
import { TokenVault } from '../TokenVault'

export type TokenRenderProps = {
  type: 'token' | 'fund' | 'lp' | 'vault'
  image_slug: TokenImageSlug | [TokenImageSlug, TokenImageSlug]
}

export type TokenRenderType = TokenRenderProps['type']
export { type TokenImageSlug }

type RenderSize = `var(--mantine-font-size-${string})` | `${number}px`
export const TokenRender = memo<
  TokenRenderProps & {
    size?: RenderSize
  }
>(function TokenRender({ type, image_slug, size = '80px' }) {
  if (image_slug instanceof Array) {
    return (
      <TokenPair first={image_slug[0]} second={image_slug[1]} size={size} />
    )
  }

  switch (type) {
    case 'token':
    case 'fund':
      return <TokenImage image_slug={image_slug} size={size} />
    case 'vault':
      return <TokenVault image_slug={image_slug} size={size} />
  }
})

export const symbol_to_render_props: Record<TokenSymbol, TokenRenderProps> = {
  USDC: { image_slug: 'usdc', type: 'token' },
  TECH: { image_slug: 'technology', type: 'fund' },
  FISN: { image_slug: 'fisn', type: 'token' },
  TVLT: { image_slug: 'technology', type: 'vault' },
}

export const TokenAndSymbolRender = memo<{ symbol: TokenSymbol }>(
  function TokenAndSymbolRender({ symbol }) {
    return (
      <Group gap="1ex" align="center" wrap="nowrap">
        <TokenRender
          size="var(--mantine-font-size-lg)"
          {...symbol_to_render_props[symbol]}
        />
        <Text size="md" lh="1">
          {symbol}
        </Text>
      </Group>
    )
  },
)
