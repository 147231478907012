/* eslint-disable max-lines-per-function */
import { Group, SimpleGrid, Stack, Text, Title } from '@mantine/core'
import { createFileRoute } from '@tanstack/react-router'

import { LinkButton } from '@repo/common/components/LinkButton'
import { useFundInfo } from '@repo/common/queries/funds'

import { CompaniesOverview } from '@/components/CompaniesOverview'
import { ContentBox } from '@/components/ContentBox'
import { FundReturnMetrics } from '@/components/FundReturnMetrics'
import { InvestmentRow } from '@/components/InvestmentRow'
import { PerShareChart } from '@/components/PerShareChart'
import { ProcessInvest } from '@/components/ProcessInvest'
import { VCLogo } from '@/components/VCLogo'
import descriptions from '@/data/funds/descriptions'
import { useTopFundInvestors } from '@/hooks/investors'

import classes from '../-support/investments.module.css'

// do to TanStack Router auto generate file routes
// prettier-ignore
export const Route = createFileRoute('/_app/investments/$symbol/')({
  component: TokenNamePage,
})

function TokenNamePage() {
  const { symbol } = Route.useParams()

  const { data: fund, isLoading } = useFundInfo({ symbol })
  const top_investors = useTopFundInvestors({ symbol, count: 3 })

  if (isLoading || fund == null) return null

  return (
    <Stack align="flex-start" w="100%">
      <Group
        gap="xs"
        justify="flex-start"
        className={classes.investment_row}
        align="flex-start"
      >
        <InvestmentRow variant="details" symbol={symbol} />
        <ProcessInvest fund_id={fund.id} />
      </Group>

      <ContentBox w="100%" p="xl" variant="fading">
        <SimpleGrid cols={{ base: 1, lg: 2 }}>
          <Stack mt="16" gap="0">
            <Title order={2}>What&apos;s in this investment?</Title>
            <CompaniesOverview fund_id={fund.id} variant="details" />
            <Title order={2} mt="lg">
              Details
            </Title>
            <Text>
              {descriptions[fund.image_slug]?.description || 'Coming Soon'}
            </Text>

            <Title order={2} mt="lg">
              Select Investors backing companies
            </Title>
            <Group>
              {top_investors.map((investor) => (
                <VCLogo
                  key={investor}
                  name={investor}
                  title={investor}
                  h="50"
                />
              ))}
            </Group>
            <Group mt="lg" mb="sm">
              <LinkButton
                to="/investments/$symbol/docs/offering"
                params={{ symbol }}
                size="sm"
                variant="light"
              >
                Offering Document
              </LinkButton>
              <LinkButton
                to="/investments/$symbol/docs/fund-overview"
                params={{ symbol }}
                size="sm"
                variant="light"
              >
                Fund Overview
              </LinkButton>
              <LinkButton
                to="/investments/$symbol/docs/disclosures"
                params={{ symbol }}
                size="sm"
                variant="light"
              >
                Disclosures
              </LinkButton>
            </Group>
          </Stack>
          <Stack gap="xl">
            <ContentBox mt="67">
              <PerShareChart fund_id={fund.id} />
            </ContentBox>
            <ContentBox p="md">
              <Title order={2} pl="md" ta="center">
                Cumulative NAV Performance
              </Title>
              <FundReturnMetrics fund_id={fund.id} />
            </ContentBox>
          </Stack>
        </SimpleGrid>
      </ContentBox>
    </Stack>
  )
}
