import { graphql } from '@repo/common/queries/helpers/server_query'

export const query_list_products = graphql(`
  query query_list_products {
    funds {
      id
      long_name
      name
      symbol
      image_slug
      type
      price_per_share

      token_address
      minter {
        address
      }
      minter_history {
        address
        version
      }
    }
  }
`)
