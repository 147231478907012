/* eslint-disable complexity */
import { memo, useCallback } from 'react'

import { Box, Button, Grid, Group, Stack, Text, Title } from '@mantine/core'
import { useAccount } from 'wagmi'

import { get_blockchain_error_message } from '@repo/common/blockchain/get_blockchain_error_message'
import { ConnectWalletButton } from '@repo/common/components/ConnectWalletButton'
import { TokenRender } from '@repo/common/components/TokenRender'
import { WalletExecuteButton } from '@repo/common/components/WalletExecuteButton'
import { countFormatter, usdFormatter } from '@repo/common/helpers/formatters'
import { useSellCartVault } from '@repo/common/queries/cart'
import { useSellVault } from '@repo/common/queries/products'
import {
  useGetVaultLatestData,
  useGetVaultSellReturnValues,
} from '@repo/common/queries/vaults'

import classes from '../PanelSell/PanelSell.module.css'

export const PanelSellPayment = memo<{
  vault_id: RubyID
  onCompleted: () => void
  onCancel: () => void
  // eslint-disable-next-line max-lines-per-function
}>(function PanelSellPayment({ onCompleted, onCancel, vault_id }) {
  const { isConnected } = useAccount()
  const cart = useSellCartVault()
  const { data } = useGetVaultLatestData({ id: vault_id })
  const vault_sell = useSellVault({ id: vault_id })
  const vault_price = Number(data?.price_per_token ?? 0)

  const balance_in = cart.values.quantity ?? 0
  const sell_usdc = balance_in * vault_price

  const return_values = useGetVaultSellReturnValues({
    id: vault_id,
    sell_usdc,
  })

  const is_ready: boolean =
    vault_sell.isReady && vault_price != null && balance_in > 0

  const onSubmit = useCallback(() => {
    if (!vault_sell.isReady) return
    vault_sell
      .mutateAsync({
        tokens: balance_in,
      })
      .then(onCompleted)
      .catch(console.error)
    // product_buy is not stable for memo
  }, [balance_in, onCompleted, vault_sell])

  let error_message: string = ''
  if (vault_sell.isReady && vault_sell.isError) {
    error_message = get_blockchain_error_message(vault_sell.error)
  }

  if (vault_price == 0) return null

  let button_content
  if (isConnected == false) {
    button_content = (
      <ConnectWalletButton
        size="lg"
        miw="13em"
        message="Connect Wallet First"
      />
    )
  } else {
    button_content = (
      <WalletExecuteButton
        disabled={!is_ready}
        loading={vault_sell.isPending}
        variant={is_ready ? 'gradient' : 'outline'}
        size="lg"
        miw="13em"
        onClick={onSubmit}
      >
        Execute Redeem
      </WalletExecuteButton>
    )
  }

  return (
    <Grid columns={2} gutter="0" className={classes.root}>
      {error_message && (
        <Grid.Col span={2} className={classes.row}>
          <Text c="red" pb="lg" role="status">
            Blockchain or Wallet error: {error_message}
          </Text>
        </Grid.Col>
      )}
      <Grid.Col span={1} className={classes.row}>
        <Group>
          <TokenRender type="vault" image_slug="technology" size="50px" />
          <Stack gap="0">
            <Title fz="xl" lh="1">
              TECH Vault
            </Title>
          </Stack>
        </Group>
      </Grid.Col>
      <Grid.Col span={1} className={classes.row_value}>
        <Text>{usdFormatter(vault_price)}/share</Text>
      </Grid.Col>
      <Grid.Col span={1} className={classes.row}>
        <Text size="sm">Redeem Type</Text>
      </Grid.Col>
      <Grid.Col span={1} className={classes.row_value}>
        <Text>Market</Text>
      </Grid.Col>
      <Grid.Col span={1} className={classes.row}>
        <Text size="sm" component="label" htmlFor="quantity">
          Redeem Amount
        </Text>
      </Grid.Col>
      <Grid.Col span={1} className={classes.row_value}>
        <Text size="lg">
          {countFormatter(balance_in, {
            maximumFractionDigits: 4,
            compact: false,
          })}
        </Text>
      </Grid.Col>
      <Grid.Col span={2}>
        <Box className={classes.line} />
      </Grid.Col>
      <Grid.Col span={1} className={classes.row}>
        Total Redeem Value
      </Grid.Col>
      <Grid.Col
        span={1}
        fw="bold"
        data-testid="total_value"
        className={classes.row_value}
      >
        {usdFormatter(sell_usdc)}
      </Grid.Col>
      <Grid.Col span={1} className={classes.row}>
        Total Return Tokens
      </Grid.Col>
      <Grid.Col span={1} fw="bold" className={classes.row_value}>
        <Group>
          <Group gap="0.5ex">
            <TokenRender
              image_slug="technology"
              type="token"
              size="var(--mantine-font-size-lg)"
            />
            <Text data-testid="total_tech">
              {countFormatter(return_values.fund_tokens, {
                maximumFractionDigits: 4,
                compact: false,
              })}
            </Text>
          </Group>
          <Group gap="0.5ex">
            <TokenRender
              image_slug="usdc"
              type="token"
              size="var(--mantine-font-size-lg)"
            />
            <Text data-testid="total_usdc">
              {countFormatter(return_values.usdc_tokens, {
                maximumFractionDigits: 2,
                compact: false,
              })}
            </Text>
          </Group>
        </Group>
      </Grid.Col>
      <Grid.Col span={2} mt="lg">
        <Group justify="flex-end">
          <Button variant="subtle" onClick={onCancel}>
            Cancel
          </Button>
          {button_content}
        </Group>
      </Grid.Col>
    </Grid>
  )
})
