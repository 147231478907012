import { memo } from 'react'

import { Box, Button, Group, Stack, Text } from '@mantine/core'

import { TokenRender } from '@repo/common/components/TokenRender'
import { usdFormatter } from '@repo/common/helpers/formatters'
// import classes from './LiquidityPosition.module.css'
import { countFormatter } from '@repo/common/helpers/formatters'
import {
  type PositionsType,
  useClosePosition,
} from '@repo/common/queries/fission_dex'

import { ActiveLabel } from './ActiveLabel'

export const LiquidityPosition = memo<{
  position: PositionsType['positions'][number]
  // eslint-disable-next-line max-lines-per-function
}>(function LiquidityPosition({ position }) {
  const send = useClosePosition({ fund_symbol: 'TECH' })

  return (
    <Group gap="xs" justify="flex-start">
      <Group gap="xs" wrap="nowrap">
        <TokenRender
          type="lp"
          size="30px"
          image_slug={['technology', 'usdc']}
        />
        <Stack gap="0">
          <Text style={{ textWrap: 'nowrap' }}>USDC ⇄ TECH</Text>
          <Text opacity={0.7}>1.0% fee tier</Text>
        </Stack>
      </Group>
      <Stack gap="xs" align="flex-start" miw="160px">
        {position.in_range && <ActiveLabel label="In Range" />}
      </Stack>
      <Box flex="1 1" />
      <Stack gap="xs">
        <Text c="dark.3" size="sm">
          Selected Range
        </Text>
        <Text style={{ textWrap: 'nowrap' }}>
          {usdFormatter(position.price_low)} -{' '}
          {usdFormatter(position.price_high)}
        </Text>
      </Stack>
      <Stack gap="xs" px="lg">
        <Text c="dark.3" size="sm">
          My Liquidity
        </Text>
        <Group gap="xs" wrap="nowrap">
          <Group gap="1ex" wrap="nowrap">
            <TokenRender
              type="token"
              size="var(--mantine-font-size-lg)"
              image_slug="usdc"
            />
            <Text>
              {countFormatter(position.usdc_pool_tokens, {
                maximumFractionDigits: 4,
              })}
            </Text>
          </Group>

          <Group gap="1ex" wrap="nowrap">
            <TokenRender
              type="token"
              size="var(--mantine-font-size-lg)"
              image_slug="technology"
            />
            <Text>
              {countFormatter(position.fund_pool_tokens, {
                maximumFractionDigits: 4,
              })}
            </Text>
          </Group>
        </Group>
      </Stack>
      <Stack gap="xs">
        <Text c="dark.3" size="sm" style={{ textWrap: 'nowrap' }}>
          Total Fees Earned
        </Text>
        <Group gap="xs" wrap="nowrap">
          <Group gap="1ex" wrap="nowrap">
            <TokenRender
              type="token"
              size="var(--mantine-font-size-lg)"
              image_slug="usdc"
            />
            <Text>
              {countFormatter(position.usdc_rewards, {
                maximumFractionDigits: 4,
              })}
            </Text>
          </Group>

          <Group gap="1ex" wrap="nowrap">
            <TokenRender
              type="token"
              size="var(--mantine-font-size-lg)"
              image_slug="technology"
            />
            <Text>
              {countFormatter(position.fund_rewards, {
                maximumFractionDigits: 4,
              })}
            </Text>
          </Group>
        </Group>
      </Stack>
      <Stack>
        <Button
          variant="subtle"
          loading={send.isPending}
          disabled={!send.isReady}
          size="sm"
          onClick={() => send.mutate({ position_id: position.id })}
        >
          Close
        </Button>
      </Stack>
    </Group>
  )
})
