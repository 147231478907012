import { Box, Grid, Group, SimpleGrid, Stack, Text, Title } from '@mantine/core'
import { createFileRoute } from '@tanstack/react-router'

import { IconTrendingUp } from '@repo/common/components/Icons'
import { TokenRender } from '@repo/common/components/TokenRender'
import { VaultAmount } from '@repo/common/components/updating_numbers/VaultAmount'
import { VaultOverallGrowth } from '@repo/common/components/updating_numbers/VaultOverallGrowth'
import { VaultPrice } from '@repo/common/components/updating_numbers/VaultPrice'
import { VaultShares } from '@repo/common/components/updating_numbers/VaultShares'
import { VaultTVL } from '@repo/common/components/updating_numbers/VaultTVL'
import { OnlyWalletBalance } from '@repo/common/components/WalletBalance'
import { full_date_formatter } from '@repo/common/helpers/formatters'
import {
  useGetVaultOriginalPrice,
  useVaultInfo,
} from '@repo/common/queries/vaults'

import { ContentBox, ContentWithTitle } from '@/components/ContentBox'
import { Holdings } from '@/components/Holdings'
import { ProcessBuyVault } from '@/components/ProcessBuyVault'
import { ProcessSell } from '@/components/ProcessSell/ProcessSell'
import { VaultPerShareChart } from '@/components/VaultPerShareChart'
import { VaultReturnMetrics } from '@/components/VaultReturnMetrics'
import { Weightings } from '@/components/Weightings'

export const Route = createFileRoute('/_app/vault/$symbol/')({
  component: VaultPage,
})

// eslint-disable-next-line max-lines-per-function
function VaultPage() {
  const { symbol } = Route.useParams()

  const { data: vault, isLoading } = useVaultInfo({ symbol })
  const { data: original_vault } = useGetVaultOriginalPrice({ id: vault?.id })

  if (isLoading || vault == null) return null

  return (
    <>
      <Stack>
        <Group align="flex-start">
          <Group w="190px" wrap="nowrap">
            <TokenRender
              type="vault"
              image_slug={vault.image_slug}
              size="var(--mantine-font-size-xl2)"
            />
            <Stack gap="0">
              <Text size="lg" fw="bold" lh="1">
                TECH Vault
              </Text>
              <Text size="sm">{vault.symbol}</Text>
            </Stack>
          </Group>
          <Group gap="lg">
            <Stack gap="0" style={{ alignSelf: 'flex-start' }}>
              <Title order={2} fz="xs" lh="2">
                Price per Share
              </Title>
              <Text size="lg" lh="md" c="green">
                <VaultPrice id={vault.id} />
              </Text>
            </Stack>
            <Stack gap="0" style={{ alignSelf: 'flex-start' }}>
              <Title order={2} fz="xs" lh="2">
                Since Inception
              </Title>

              <Group gap="xxs" fz="lg" lh="md" c="green">
                <Box top="0.3ex" pos="relative">
                  <IconTrendingUp size="1em" />
                </Box>
                <Text lh="md">
                  <VaultOverallGrowth id={vault.id} /> APY
                </Text>
              </Group>
            </Stack>
          </Group>
        </Group>

        <Grid>
          <Grid.Col span={{ base: 12, md: 8 }}>
            <ContentBox
              w="100%"
              h="450px"
              pl="sm"
              pt="lg"
              pr="lg"
              pb="xxs"
              pos="relative"
            >
              <VaultPerShareChart id={vault.id} />
            </ContentBox>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 4 }}>
            <ContentBox w="100%" h="450px" p="xl" justify="space-between">
              <Stack gap="0">
                <Text size="sm" opacity={0.6} lh="1.56">
                  Total Value Locked (TVL)
                </Text>
                <Text size="xxl" lh="1.25">
                  <VaultTVL id={vault.id} />
                </Text>
              </Stack>
              <SimpleGrid cols={2} verticalSpacing="sm">
                <Text size="md" opacity={0.8} lh="1.25">
                  Price per token
                </Text>
                <Text ta="right" lh="1.25">
                  <VaultPrice id={vault.id} />
                </Text>
                <Text size="md" opacity={0.8} lh="1.25">
                  Tokens
                </Text>
                <Text ta="right" lh="1.25">
                  <VaultShares id={vault.id} />
                </Text>
                <Text size="md" opacity={0.8} lh="1.25">
                  Inspection Date
                </Text>
                <Text ta="right" lh="1.25">
                  {full_date_formatter(original_vault?.date)}
                </Text>
              </SimpleGrid>
              <Box h="1px" bg="dark.8" w="100%" my="2px" flex="0 0 1px" />
              <SimpleGrid cols={2} verticalSpacing="sm">
                <Text size="md" opacity={0.8} lh="1.25">
                  My Balance
                </Text>
                <Text ta="right" lh="1.25">
                  <VaultAmount id={vault.id} />
                </Text>
                <Text size="md" opacity={0.8} lh="1.25">
                  My Tokens
                </Text>
                <Text ta="right" lh="1.25">
                  <OnlyWalletBalance token="TVLT" />
                </Text>
              </SimpleGrid>
              <Stack gap="xxs">
                <ProcessSell vault_id={vault.id} variant="subtle">
                  Redeem TVLT
                </ProcessSell>
                <ProcessBuyVault vault_id={vault.id}>
                  Provide Liquidity (TECH & USDC)
                </ProcessBuyVault>
              </Stack>
            </ContentBox>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 8 }}>
            <ContentWithTitle title="Return Metrics">
              <VaultReturnMetrics id={vault.id} />
            </ContentWithTitle>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 4 }}>
            <ContentWithTitle title="About">
              <Text size="sm" opacity={0.6}>
                The vault automates liquidity provisioning to our exchange.
                Provide Liquidity using TECH and USDC in our vault, earn trading
                fees and redeem at any time. Holding assets in our vault earns
                users governance token rewards.
              </Text>
            </ContentWithTitle>
          </Grid.Col>
          <Grid.Col span={12}>
            <ContentWithTitle title={`${vault.symbol} Holdings`}>
              <Holdings id={vault.id} />
            </ContentWithTitle>
          </Grid.Col>
          <Grid.Col span={12}>
            <ContentWithTitle title="Weighting">
              <Weightings id={vault.id} />
            </ContentWithTitle>
          </Grid.Col>
        </Grid>
      </Stack>
    </>
  )
}
