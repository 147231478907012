import { memo } from 'react'

import { TextInput } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import isUUID from 'validator/es/lib/isUUID'
import { z } from 'zod'

import {
  form_input_props,
  LoginSignupForm,
} from '@repo/common/components/LoginSignupForm'

import { useConfirmEmail } from '../../queries/auth'

const schema = z.object({
  uuid: z.string().refine(isUUID),
  token: z.string().min(6),
})

export const email_confirm_search_schema = z.object({
  uuid: z.string().refine(isUUID).or(z.undefined()).catch(undefined),
  token: z.string().min(8).or(z.undefined()).catch(undefined),
})

export const EmailConfirm = memo<{
  uuid: string
  token?: string
  onSuccess?: () => void
}>(function EmailConfirm({ uuid, token, onSuccess }) {
  const form = useForm({
    initialValues: {
      uuid,
      token,
    },
    validate: zodResolver(schema),
  })
  const confirm = useConfirmEmail()

  return (
    <LoginSignupForm
      form={form}
      action={confirm}
      onSuccess={onSuccess}
      button_text="Confirm Email"
    >
      <TextInput
        label="confirm code"
        type="password"
        {...form.getInputProps('token')}
        disabled={confirm.isPending}
        {...form_input_props}
      />
    </LoginSignupForm>
  )
})
