import { Button, Group, SimpleGrid, Stack, Text, Title } from '@mantine/core'
import { createFileRoute } from '@tanstack/react-router'

import { IconPlus } from '@repo/common/components/Icons'
import { LinkButton } from '@repo/common/components/LinkButton'
import { TokenRender } from '@repo/common/components/TokenRender'
import { percentage_formatter } from '@repo/common/helpers/formatters'
import { useGetTokenPrice } from '@repo/common/queries/fission_dex'
import { useFundInfo } from '@repo/common/queries/funds'
import { useGetGovMintRewards } from '@repo/common/queries/gov'

import { ContentBox } from '@/components/ContentBox'
import { HeadlineAndDetails } from '@/components/HeadlineAndDetails'

export const Route = createFileRoute('/_app/overview')({
  component: ProtocolPage,
})

// eslint-disable-next-line max-lines-per-function
function ProtocolPage() {
  const { data: fund } = useFundInfo({ symbol: 'TECH' })
  const { data: fisn_rewards } = useGetGovMintRewards({
    mint_value: 10000,
    fund_id: fund?.id,
  })
  const is_coming_soon = fund?.type == null
  const price_result = useGetTokenPrice({
    symbol: is_coming_soon ? undefined : fund?.symbol,
  })

  const show_mint =
    price_result.data != null &&
    fund != null &&
    fund.price_per_share < price_result.data

  return (
    <Stack gap="xxl">
      <HeadlineAndDetails
        title="Fission Protocol"
        description="An exchange and automated liquidity provider for tokenized venture fund company"
      />
      <SimpleGrid cols={{ base: 1, sm: 2, md: 3 }}>
        <ContentBox p="xl" gap="0" justify="space-between">
          <Stack gap="0">
            <Group justify="space-between">
              <TokenRender type="token" image_slug="technology" size="72px" />
              {show_mint && (
                <Stack gap="0" align="flex-end">
                  <Group c="green.4" fz="lg" gap="0">
                    <IconPlus size="1em" />
                    <Text fz="lg" lh="1.1">
                      {percentage_formatter(fisn_rewards.percentage)} on Mint
                    </Text>
                  </Group>
                  <Group gap="0.5ex">
                    <TokenRender
                      image_slug="fisn"
                      type="token"
                      size="var(--mantine-font-size-lg)"
                    />
                    <Text size="md">FISN Rewards</Text>
                  </Group>
                </Stack>
              )}
            </Group>
            <Group gap="xs" align="baseline">
              <Title fz="title_xs" mt="md">
                TECH Holding
              </Title>
              <Text size="sm" opacity={0.8}>
                TECH
              </Text>
            </Group>
            <Text opacity={0.6} mt="xxs">
              TECH is a tradeable tokenized late stage venture holding company
              from Fission containing assets such as SpaceX, Boring Company, X
              (Twitter), XAI, Neuralink, Stripe, Circle, Discord, and others...
            </Text>
          </Stack>
          <LinkButton
            to="/investments/$symbol"
            params={{ symbol: 'TECH' }}
            mt="xl"
          >
            Invest in TECH
          </LinkButton>
        </ContentBox>
        <ContentBox p="xl" gap="0" justify="space-between">
          <Stack gap="0">
            <Group justify="space-between">
              <TokenRender type="vault" image_slug="technology" size="72px" />
              <Stack gap="0" align="flex-end">
                <Group c="green.4" fz="lg" gap="0">
                  <IconPlus size="1em" />
                  <Text fz="lg" lh="1.1">
                    20% APY
                  </Text>
                </Group>
                <Group gap="0.5ex">
                  <TokenRender
                    image_slug="fisn"
                    type="token"
                    size="var(--mantine-font-size-lg)"
                  />
                  <Text size="md">FISN Rewards</Text>
                </Group>
              </Stack>
            </Group>
            <Group gap="xs" align="baseline">
              <Title fz="title_xs" mt="md">
                TECH Vault
              </Title>
              <Text size="sm" opacity={0.8}>
                TVLT
              </Text>
            </Group>
            <Text opacity={0.6} mt="xxs">
              Provide Liquidity using TECH and USDC in our Vault and earn
              trading fees and governance token rewards. Redeem when you want.
              Automated liquidity provisioning to maximize your returns.
            </Text>
          </Stack>
          <LinkButton to="/vault/$symbol" params={{ symbol: 'TVLT' }} mt="xl">
            Provide Liquidity (TECH & USDC)
          </LinkButton>
        </ContentBox>
        <ContentBox p="xl" gap="0" justify="space-between">
          <Stack gap="0">
            <TokenRender type="token" image_slug="fisn" size="72px" />
            <Group gap="xs" align="baseline">
              <Title fz="title_xs" mt="md">
                Fission Governance
              </Title>
              <Text size="sm" opacity={0.8}>
                FISN
              </Text>
            </Group>
            <Text opacity={0.6} mt="xxs">
              Governance token holders earn fees from the Fission Protocol and
              vote to determine the future of the Fission Foundation.
            </Text>
          </Stack>
          <Button mt="xl" disabled>
            Buy FISN
          </Button>
        </ContentBox>
      </SimpleGrid>
    </Stack>
  )
}
