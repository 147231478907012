import { Stack } from '@mantine/core'
import { createFileRoute } from '@tanstack/react-router'
import dayjs from 'dayjs'

import { PortfolioChart } from '@repo/common/components/PortfolioChart'
import { PortfolioLedger } from '@repo/common/components/PortfolioLedger'
import { PortfolioTable } from '@repo/common/components/PortfolioTable'
import { useAuth } from '@repo/common/queries/auth'

import { ContentBox } from '@/components/ContentBox'
import { HeadlineAndDetails } from '@/components/HeadlineAndDetails'
import { PortfolioReturnMetrics } from '@/components/PortfolioReturnMetrics'
import { ProfileActions } from '@/components/ProfileActions'

// do to TanStack Router auto generate file routes
// prettier-ignore
export const Route = createFileRoute('/_app/portfolio')({
  component: IndexPage,
})

function IndexPage() {
  const auth = useAuth()

  let name = auth.data?.name
  if (name) name += name.endsWith('s') ? "'" : "'s"
  if (!name) name = 'My'

  return (
    <Stack gap="xl">
      <HeadlineAndDetails
        title={`${name} Portfolio`}
        description={auth.switch(
          `As of ${dayjs().format('MMMM DD')}`,
          'Please connect your wallet to view your portfolio.',
        )}
      />
      <ContentBox
        w="100%"
        variant="fading"
        h="450px"
        pl="xxs"
        pt="lg"
        pr="lg"
        pb="xxs"
      >
        <PortfolioChart />
      </ContentBox>
      <ContentBox w="100%" variant="fading" p="xl">
        <PortfolioReturnMetrics />
      </ContentBox>
      <ContentBox w="100%" variant="fading" p="xl">
        <PortfolioTable />
      </ContentBox>
      <ContentBox w="100%" variant="fading" p="xl">
        <PortfolioLedger />
      </ContentBox>
      <ProfileActions />
    </Stack>
  )
}
