import { memo, useMemo } from 'react'

import { Center, Paper, Text } from '@mantine/core'
import isChromatic from 'chromatic'
import dayjs from 'dayjs'
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import {
  date_formatter,
  full_date_formatter,
  tick_wrapper,
  usdFormatter,
  usdLargeNumberFormatter,
} from '@repo/common/helpers/formatters'

import {
  type BalanceRecord,
  useGetPortfolioHistory,
} from '../../queries/portfolio'
import { useListPortfolio } from '../../queries/portfolio'
import { ConnectWalletButton } from '../ConnectWalletButton'

export const y_tick_formatting = {
  fontSize: 'var(--mantine-font-size-sm)',
  fill: 'var(--mantine-color-dark-0)',
  opacity: 0.8,
}

export const x_tick_formatting = {
  fontSize: 'var(--mantine-font-size-sm)',
  fill: 'var(--mantine-color-dark-0)',
  opacity: 0.6,
}

export const Balance_Colors = {
  stroke: 'var(--mantine-color-green-5)',
  fill: 'var(--mantine-color-green-5)',
}

export const PortfolioChart = memo(function PortfolioChart() {
  const history = useGetPortfolioHistory()
  const { totals, isConnected } = useListPortfolio()

  const data = useMemo(() => {
    const latest: BalanceRecord = {
      day: dayjs().startOf('day').valueOf(),
      balance_usdc_value: totals.usdc_value,
      components: {} as BalanceRecord['components'],
    }
    return [...history, latest]
  }, [history, totals.usdc_value])

  if (!isConnected) {
    return (
      <Center h="100%">
        <ConnectWalletButton message="Connect Wallet to see history" />
      </Center>
    )
  }
  return (
    <ResponsiveContainer>
      <ComposedChart data={data}>
        <CartesianGrid
          strokeDasharray="3 3"
          stroke="var(--mantine-color-dark-0)"
          opacity={0.4}
        />
        <XAxis
          dataKey="day"
          scale="time"
          type="number"
          domain={['dataMin', 'dataMax']}
          interval="preserveStartEnd"
          tick={x_tick_formatting}
          tickFormatter={tick_wrapper(date_formatter)}
        />
        <YAxis
          yAxisId="left"
          type="number"
          domain={['auto', 'auto']}
          tick={y_tick_formatting}
          tickFormatter={tick_wrapper(usdLargeNumberFormatter)}
        />
        <Tooltip
          content={({ label, payload }) => (
            <ChartTooltip
              label={label as ToolTipType['label']}
              payload={payload as ToolTipType['payload']}
            />
          )}
        />

        <Line
          yAxisId="left"
          isAnimationActive={!isChromatic()}
          type="stepAfter"
          dataKey="balance_usdc_value"
          {...Balance_Colors}
          strokeWidth={2}
          dot={false}
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
})

export type ToolTipType = {
  label: number
  payload?: {
    name: string
    color: string
    value: number
    payload: BalanceRecord
  }[]
}

export function ChartTooltip({ label, payload }: ToolTipType) {
  if (payload == null || payload?.length < 1) return null

  return (
    <Paper px="md" py="sm" withBorder shadow="md" radius="md">
      <Text size="sm" mb={5}>
        {full_date_formatter(label)}
      </Text>
      <Text size="md" c={payload[0].color}>
        Total Balance: {usdFormatter(payload[0].value)}
      </Text>
    </Paper>
  )
}
