import { graphql } from '@repo/common/queries/helpers/server_query'

export const mutation_reset_password = graphql(`
  mutation reset_password_email($input: ResetPasswordInput!) {
    reset_password_email(input: $input) {
      status
    }
  }
`)

export const mutation_confirm_email = graphql(`
  mutation confirm_email {
    confirm_email {
      status
    }
  }
`)
