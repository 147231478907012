import { memo, useMemo } from 'react'

import dayjs from 'dayjs'
import * as _ from 'lodash-es'
import { convertRate, xirr } from 'node-irr'

import { daily_rate_to_APY } from '@repo/common/calculations/apy_math'
import { type MetricData, Metrics } from '@repo/common/components/Metrics'
import { percentage_formatter } from '@repo/common/helpers/formatters'
import {
  type BalanceRecord,
  type LedgerRecord,
  useGetPortfolioHistory,
  useGetPortfolioLedger,
  useListPortfolio,
} from '@repo/common/queries/portfolio'

const USE_ALL_HISTORY = null

// eslint-disable-next-line complexity
export function to_rate({
  ledger,
  history,
  since_date,
  todays_value,
}: {
  ledger: LedgerRecord[]
  history: BalanceRecord[]
  since_date: Parameters<typeof dayjs>[0] | null
  todays_value: { date: Date; amount: number }
}) {
  if (ledger.length === 0) return { rate: 'n/a' }

  let ledger_subset = _.sortBy(ledger, 'timestamp')
  if (
    since_date != USE_ALL_HISTORY &&
    dayjs(ledger_subset[0].timestamp).isSame(since_date, 'day')
  ) {
    since_date = USE_ALL_HISTORY
  }

  if (since_date != USE_ALL_HISTORY) {
    ledger_subset = ledger_subset.filter((record) =>
      dayjs(record.timestamp).isAfter(since_date, 'day'),
    )
  }

  const xirr_records = ledger_subset.map((record) => ({
    date: dayjs(record.timestamp).toDate(),
    amount: record.xirr_change_value,
  }))
  xirr_records.push(todays_value)

  if (since_date != USE_ALL_HISTORY) {
    const h_record = history.find((h) => dayjs(h.day).isSame(since_date, 'day'))
    xirr_records.unshift({
      date: dayjs(since_date).toDate(),
      amount: -(h_record?.balance_usdc_value ?? 0),
    })
  }

  if (xirr_records.length < 2) return { rate: 'n/a' }

  const results = xirr(xirr_records, { maxIterations: 200 })

  const period_rate = convertRate(results.rate, results.days)

  return {
    rate: percentage_formatter(100 * period_rate),
    apy: daily_rate_to_APY(results.rate),
  }
}

const date_or_inception = (
  date: Parameters<typeof dayjs>[0],
  inception: Parameters<typeof dayjs>[0],
) => {
  if (dayjs(date).isBefore(inception, 'day')) return dayjs(inception)
  return dayjs(date)
}

// eslint-disable-next-line max-lines-per-function
export const useGetPortfolioMetrics = () => {
  const ledger_query = useGetPortfolioLedger()
  const portfolio_query = useListPortfolio()
  const history = useGetPortfolioHistory()

  const ledger = useMemo(() => {
    return ledger_query.ledger.filter((r) => r.xirr_change_value != 0)
  }, [ledger_query.ledger])

  // eslint-disable-next-line complexity, max-lines-per-function
  return useMemo(() => {
    const inception_date = dayjs(_.minBy(ledger, 'timestamp')?.timestamp)
    if (
      ledger_query.isLoading ||
      portfolio_query.isLoading ||
      ledger.length == 0 ||
      !inception_date.isBefore(dayjs(), 'day')
    )
      return null

    const todays_value = {
      date: new Date(),
      amount: portfolio_query.totals.usdc_value,
    }
    const one_month = date_or_inception(
      dayjs().subtract(1, 'month'),
      inception_date,
    )
    const one_quarter = date_or_inception(
      dayjs().subtract(3, 'month'),
      inception_date,
    )
    const jan_1 = date_or_inception(dayjs().startOf('year'), inception_date)
    const one_year = date_or_inception(
      dayjs().subtract(1, 'year'),
      inception_date,
    )

    return [
      {
        label: '1 Month',
        since_date: one_month,
        rate: to_rate({
          ledger,
          since_date: one_month,
          history,
          todays_value,
        }).rate,
      },
      {
        label: '3 Month',
        since_date: one_quarter,
        rate: to_rate({
          ledger,
          since_date: one_quarter,
          history,
          todays_value,
        }).rate,
      },
      {
        label: 'YTD',
        since_date: jan_1,
        ...to_rate({
          ledger,
          since_date: jan_1,
          history,
          todays_value,
        }),
      },
      {
        label: '1 Year',
        since_date: one_year,
        ...to_rate({
          ledger,
          since_date: one_year,
          history,
          todays_value,
        }),
      },
      {
        label: 'Inception',
        since_date: inception_date,
        ...to_rate({
          ledger,
          since_date: null,
          history,
          todays_value,
        }),
      },
    ] satisfies MetricData as MetricData
  }, [
    history,
    ledger,
    ledger_query.isLoading,
    portfolio_query.isLoading,
    portfolio_query.totals.usdc_value,
  ])
}

export const PortfolioReturnMetrics = memo(function PortfolioReturnMetrics() {
  const metrics = useGetPortfolioMetrics()

  if (metrics == null) return null

  return <Metrics data={metrics} />
})
