import { memo } from 'react'

import { Button, Group, Stack, Text } from '@mantine/core'
import { Link } from '@tanstack/react-router'

import { useIsAuthenticated, useLogout } from '@repo/common/queries/auth'

import { Logo } from '../../../../apps/labs/src/components/Logo'

export const LoginLogout = memo(function LoginLogout() {
  const reset = useLogout()
  const is_auth = useIsAuthenticated()

  return (
    <Stack miw="300" align="center">
      <Group justify="center">
        <Logo size={120} />
      </Group>

      {!is_auth ? (
        <>
          <Text size="lg">Already logged out</Text>
          <Text size="lg">
            <Link to="/login">Login</Link> instead?
          </Text>
        </>
      ) : (
        <Button
          loading={reset.isPending}
          w="100%"
          size="lg"
          mt="lg"
          disabled={!is_auth}
          onClick={() => reset.mutate()}
        >
          Logout?
        </Button>
      )}
    </Stack>
  )
})
