import { memo, useEffect } from 'react'

import { Box, Button, Group, NumberInput, Stack, Text } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { useFocusWithin } from '@mantine/hooks'
import { useNavigate } from '@tanstack/react-router'
import { z } from 'zod'

import { LiquidityChart } from '@repo/common/components/LiquidityChart'
import { TokenRender } from '@repo/common/components/TokenRender'
import { countFormatter } from '@repo/common/helpers/formatters'
import { useGetWalletBalance } from '@repo/common/queries/blockchain/useGetWalletBalance'
import {
  type InSideType,
  useCreatePosition,
  useGetPositionAmounts,
  useGetTokenPrice,
} from '@repo/common/queries/fission_dex'

import { ContentBox } from '../ContentBox'

import classes from './AddLiquidity.module.css'

const default_reason = 'Enter deposits'

export const AddLiquidity = memo<{ fund_symbol: 'TECH' }>(
  // eslint-disable-next-line max-lines-per-function, complexity
  function AddLiquidity({ fund_symbol }) {
    const navigate = useNavigate()
    const form = useForm({
      mode: 'controlled',
      initialValues: {
        deposit_token: undefined,
        deposit_usdc: undefined,
        price_low: undefined,
        price_high: undefined,
        in_side: 'TOKEN',
      } as {
        deposit_token: number | undefined
        deposit_usdc: number | undefined
        price_low: number | undefined
        price_high: number | undefined
        in_side: InSideType
      },
      validateInputOnChange: true,
      validate: (values) => {
        return zodResolver(get_schema(values))(values)
      },
    })
    const create_position = useCreatePosition({ fund_symbol })

    const price_low_focus = useFocusWithin()
    const price_high_focus = useFocusWithin()
    const deposit_token_focus = useFocusWithin()
    const deposit_usdc_focus = useFocusWithin()

    const { balance: balance_token } = useGetWalletBalance(fund_symbol)
    const { balance: balance_usdc } = useGetWalletBalance('USDC')
    const { data: tech_price } = useGetTokenPrice({ symbol: fund_symbol })
    const updates = useGetPositionAmounts({ fund_symbol, ...form.getValues() })

    const onSubmit = (
      values: Parameters<Parameters<typeof form.onSubmit>[0]>[0],
    ) => {
      create_position
        .mutateAsync(values)
        .then(() => navigate({ to: '/portfolio' }))
        .catch(console.error)
    }

    // eslint-disable-next-line complexity
    useEffect(() => {
      if (!updates.enabled) return
      const values: Parameters<typeof form.setValues>[0] = {}
      if (
        updates.price_low != form.getValues().price_low &&
        !price_low_focus.focused
      ) {
        values.price_low = updates.price_low
      }
      if (
        updates.price_high != form.getValues().price_high &&
        !price_high_focus.focused
      ) {
        values.price_high = updates.price_high
      }
      const both_zero = !(
        (form.getValues().deposit_token ?? 0) > 0 ||
        (form.getValues().deposit_usdc ?? 0) > 0
      )
      if (
        !both_zero &&
        updates.deposit_usdc != form.getValues().deposit_usdc &&
        (form.getValues().in_side != 'USDC' || updates.deposit_usdc == 0) &&
        !deposit_usdc_focus.focused
      ) {
        values.deposit_usdc = updates.deposit_usdc
      }
      if (
        !both_zero &&
        updates.deposit_token != form.getValues().deposit_token &&
        (form.getValues().in_side != 'TOKEN' || updates.deposit_token == 0) &&
        !deposit_token_focus.focused
      ) {
        values.deposit_token = updates.deposit_token
      }

      if (Object.keys(values).length > 0) {
        form.setValues(values)
      }
    }, [
      deposit_token_focus.focused,
      deposit_usdc_focus.focused,
      form,
      price_high_focus.focused,
      price_low_focus.focused,
      updates.deposit_token,
      updates.deposit_usdc,
      updates.enabled,
      updates.price_high,
      updates.price_low,
    ])

    form.watch('deposit_token', () => {
      if (!deposit_token_focus.focused) return

      if (form.getValues().in_side != 'TOKEN') {
        form.setValues({ in_side: 'TOKEN' })
      }
    })

    form.watch('deposit_usdc', () => {
      if (!deposit_usdc_focus.focused) return

      if (form.getValues().in_side != 'USDC') {
        form.setValues({ in_side: 'USDC' })
      }
    })

    useEffect(() => {
      const half_range = 0.03
      if (tech_price == null) return
      if (form.getValues().price_low == null) {
        form.setValues({ price_low: tech_price * (1 - half_range) })
      }
      if (form.getValues().price_high == null) {
        form.setValues({ price_high: tech_price * (1 + half_range) })
      }
    }, [form, tech_price])

    const get_schema = (values: {
      deposit_token: number | undefined
      deposit_usdc: number | undefined
      price_low: number | undefined
      price_high: number | undefined
    }) => {
      return z.object({
        price_low: z.number({ message: 'Enter low price' }).positive(),
        price_high: z
          .number({ message: 'Enter high price' })
          .positive()
          .gt(values.price_low ?? 0, {
            message: 'High price is below low price',
          }),
        deposit_token:
          updates.deposit_token == 0 && updates.deposit_usdc > 0
            ? z.number({ message: default_reason })
            : z
                .number({ message: default_reason })
                .positive()
                .max(balance_token, {
                  message: `Insufficient ${fund_symbol} balance`,
                }),
        deposit_usdc:
          updates.deposit_usdc == 0 && updates.deposit_token > 0
            ? z.number({ message: default_reason })
            : z
                .number({ message: default_reason })
                .positive()
                .max(balance_usdc, {
                  message: `Insufficient USDC balance`,
                }),
      })
    }

    const enabled = form.isValid()
    const reason =
      get_schema(form.getValues()).safeParse(form.getValues()).error?.errors[0]
        ?.message || default_reason
    const usdc_disabled = updates.deposit_usdc === 0 && updates.outside_current
    const token_disabled =
      updates.deposit_token === 0 && updates.outside_current

    if (tech_price == null) return null

    return (
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack maw="calc(100vw - 20px)" w="400px">
          <Stack gap="0">
            <Text size="sm" opacity={0.7}>
              Selected Pool
            </Text>
            <Group>
              <Group gap="1ex">
                <TokenRender
                  type="token"
                  size="var(--mantine-font-size-lg)"
                  image_slug="technology"
                />
                <Text>TECH</Text>
              </Group>
              <Group gap="1ex">
                <TokenRender
                  type="token"
                  size="var(--mantine-font-size-lg)"
                  image_slug="usdc"
                />
                <Text>USDC</Text>
              </Group>
              <Text opacity={0.7}>1.0% fee tier</Text>
            </Group>
          </Stack>
          <Stack gap="0">
            <Text size="md">Set Price Range</Text>
            <Stack gap="xxs">
              <ContentBox
                p="xs"
                gap="0"
                ref={price_low_focus.ref}
                mod={{ focused: price_low_focus.focused }}
              >
                <Text size="xs" opacity={0.7}>
                  Low price
                </Text>
                <NumberInput
                  {...form.getInputProps('price_low', { withError: false })}
                  classNames={{ input: classes.price_input }}
                  aria-label="low price"
                  variant="unstyled"
                  allowNegative={false}
                  decimalScale={2}
                  hideControls
                  placeholder="0"
                  flex="1 1"
                />
                <Text size="xs" opacity={0.7}>
                  USDC per TECH
                </Text>
              </ContentBox>
              <ContentBox
                p="xs"
                gap="lg"
                direction="row"
                style={{ zIndex: 10 }}
              >
                <Stack gap="0">
                  <Text size="xs" opacity={0.7}>
                    Current price
                  </Text>
                  <Text size="xl" lh="1.42">
                    {countFormatter(tech_price, {
                      compact: false,
                      maximumFractionDigits: 2,
                    })}
                  </Text>
                  <Text size="xs" opacity={0.7}>
                    USDC per TECH
                  </Text>
                </Stack>
                <Box flex="1 1" pr="xs" mb="-30px">
                  <LiquidityChart
                    height={75}
                    graph_font_size="var(--mantine-font-size-xs)"
                  />
                </Box>
              </ContentBox>
              <ContentBox
                p="xs"
                gap="0"
                ref={price_high_focus.ref}
                mod={{ focused: price_high_focus.focused }}
              >
                <Text size="xs" opacity={0.7}>
                  High price
                </Text>
                <NumberInput
                  {...form.getInputProps('price_high', { withError: false })}
                  classNames={{ input: classes.price_input }}
                  aria-label="high price"
                  variant="unstyled"
                  allowNegative={false}
                  decimalScale={2}
                  hideControls
                  placeholder="0"
                  flex="1 1"
                />
                <Text size="xs" opacity={0.7}>
                  USDC per TECH
                </Text>
              </ContentBox>
            </Stack>
          </Stack>
          <Stack gap="0">
            <Text size="md">Deposit amounts</Text>
            <Stack gap="xxs">
              <ContentBox
                p="xs"
                gap="0"
                ref={deposit_token_focus.ref}
                mod={{ focused: deposit_token_focus.focused }}
                direction="row"
                opacity={token_disabled ? 0.2 : 1}
              >
                <NumberInput
                  {...form.getInputProps('deposit_token', { withError: false })}
                  disabled={token_disabled}
                  opacity={token_disabled ? 0 : 1}
                  classNames={{ input: classes.deposit_input }}
                  aria-label="low price"
                  variant="unstyled"
                  allowNegative={false}
                  decimalScale={2}
                  hideControls
                  placeholder="0"
                  flex="1 1"
                />
                <Stack gap="0">
                  <Group justify="flex-end" gap="1ex" mr="xxs">
                    <TokenRender
                      type="token"
                      size="var(--mantine-font-size-lg)"
                      image_slug="technology"
                    />
                    <Text>{fund_symbol}</Text>
                  </Group>
                  <Group justify="flex-end" align="baseline" gap="0">
                    <Text size="sm" opacity={0.7}>
                      Balance: {balance_token}
                    </Text>
                    <Button
                      size="compact-sm"
                      variant="subtle"
                      onClick={() =>
                        form.setFieldValue('deposit_token', balance_token)
                      }
                    >
                      MAX
                    </Button>
                  </Group>
                </Stack>
              </ContentBox>
              <ContentBox
                p="xs"
                gap="0"
                ref={deposit_usdc_focus.ref}
                mod={{ focused: deposit_usdc_focus.focused }}
                direction="row"
                opacity={usdc_disabled ? 0.2 : 1}
              >
                <NumberInput
                  {...form.getInputProps('deposit_usdc', { withError: false })}
                  disabled={usdc_disabled}
                  opacity={usdc_disabled ? 0 : 1}
                  classNames={{ input: classes.deposit_input }}
                  aria-label="low price"
                  variant="unstyled"
                  allowNegative={false}
                  decimalScale={2}
                  hideControls
                  placeholder="0"
                  flex="1 1"
                />
                <Stack gap="0">
                  <Group justify="flex-end" gap="1ex" mr="xxs">
                    <TokenRender
                      type="token"
                      size="var(--mantine-font-size-lg)"
                      image_slug="usdc"
                    />
                    <Text>USDC</Text>
                  </Group>
                  <Group justify="flex-end" align="baseline" gap="0">
                    <Text size="sm" opacity={0.7}>
                      Balance: {balance_usdc}
                    </Text>
                    <Button
                      size="compact-sm"
                      variant="subtle"
                      onClick={() =>
                        form.setFieldValue('deposit_usdc', balance_usdc)
                      }
                    >
                      MAX
                    </Button>
                  </Group>
                </Stack>
              </ContentBox>
            </Stack>
          </Stack>

          <Button
            disabled={!enabled}
            loading={create_position.isPending}
            variant={enabled ? 'gradient' : 'outline'}
            size="lg"
            type="submit"
          >
            {enabled ? 'Create Position' : reason}
          </Button>
        </Stack>
      </form>
    )
  },
)
