import { memo } from 'react'

import { ActionIcon } from '@mantine/core'

import { IconCaretDown } from '@repo/common/components/Icons'

import classes from './ActionHideShow.module.css'

export const ActionHideShow = memo<{
  toggle: () => void
  opened: boolean
  item_noun: string
}>(function ActionHideShow({ toggle, opened, item_noun }) {
  return (
    <ActionIcon
      variant="subtle"
      onClick={toggle}
      className={classes.details_button}
      mod={{ opened }}
      aria-label={`toggle ${item_noun}`}
    >
      <IconCaretDown stroke={1.5} />
    </ActionIcon>
  )
})
