import { memo } from 'react'

import { Group, Progress, Stack, Text, Title, Tooltip } from '@mantine/core'

import { ContentBox } from '../../../../apps/labs/src/components/ContentBox'
import {
  usdLargeNumberFormatter,
  value_formatter,
} from '../../helpers/formatters'
import { useGetVaultComposition } from '../../queries/vaults'
import { Liquidity_Colors } from '../LiquidityChart'
import { TokenRender } from '../TokenRender'

export const VaultUndeployedStats = memo<{ id: RubyID }>(
  function VaultUndeployedStats({ id }) {
    const { data: vault } = useGetVaultComposition({ id })

    const fund_symbol = 'TECH'

    const usdc = vault?.composition.find((c) => c.name === 'USDC')
    const fund = vault?.composition.find((c) => c.name === fund_symbol)

    if (fund == null || usdc == null) return null

    return (
      <ContentBox gap="lg" p="md" w="100%">
        <Stack gap="xs">
          <Title order={2} lh="1">
            Undeployed Balances
          </Title>
          <Group justify="space-between">
            <Group gap="0.25em">
              <Text size="md">{value_formatter(usdc.shares)}</Text>
              <TokenRender
                type="token"
                size="var(--mantine-font-size-lg)"
                image_slug="usdc"
              />
              <Text size="md">USDC</Text>
            </Group>
            <Group gap="0.25em">
              <Text size="md">{value_formatter(fund.shares)}</Text>
              <TokenRender
                type="token"
                size="var(--mantine-font-size-lg)"
                image_slug="technology"
              />
              <Text size="md">{fund_symbol}</Text>
            </Group>
          </Group>
          <Progress.Root size="xl">
            <Tooltip
              label={`USDC ${usdLargeNumberFormatter(usdc.usdc_value, { maximumFractionDigits: 2 })}`}
            >
              <Progress.Section
                value={
                  (100 * usdc.usdc_value) / (usdc.usdc_value + fund.usdc_value)
                }
                color={Liquidity_Colors.usdc}
              />
            </Tooltip>
            <Tooltip
              label={`${fund_symbol} ${usdLargeNumberFormatter(fund.usdc_value, { maximumFractionDigits: 2 })}`}
            >
              <Progress.Section
                value={
                  (100 * fund.usdc_value) / (usdc.usdc_value + fund.usdc_value)
                }
                color={Liquidity_Colors.token}
              />
            </Tooltip>
          </Progress.Root>
        </Stack>
      </ContentBox>
    )
  },
)
