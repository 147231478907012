import { Fragment, memo } from 'react'

import { Table } from '@mantine/core'
import * as _ from 'lodash-es'

import { useListCryptoContracts } from '@repo/common/queries/crypto_contracts'
import { useListProducts } from '@repo/common/queries/products'

import { CryptoLookupTxLink } from '../CryptoLookupTxLink'

export const ShowAddresses = memo(function ShowAddresses() {
  const { data: products } = useListProducts()
  const {
    data: { contracts },
  } = useListCryptoContracts()
  return (
    <Table>
      <Table.Tbody>
        {_.sortBy(products, 'symbol').map((p) => (
          <Fragment key={p.symbol}>
            <Table.Tr>
              <Table.Td ta="right">{p.symbol}</Table.Td>
              <Table.Td>
                <CryptoLookupTxLink address={p.token_address}>
                  {p.token_address}
                </CryptoLookupTxLink>
              </Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td ta="right">{p.symbol} minter</Table.Td>
              <Table.Td>
                <CryptoLookupTxLink address={p.minter_address}>
                  {p.minter_address}
                </CryptoLookupTxLink>
              </Table.Td>
            </Table.Tr>
          </Fragment>
        ))}
        {_.sortBy(_.filter(contracts, { category: 'token' }), 'name').map(
          (c) => (
            <Table.Tr key={c.name}>
              <Table.Td ta="right">{c.symbol || c.name}</Table.Td>
              <Table.Td>
                <CryptoLookupTxLink address={c.address}>
                  {c.address}
                </CryptoLookupTxLink>
              </Table.Td>
            </Table.Tr>
          ),
        )}
        {_.sortBy(_.filter(contracts, { category: 'dex' }), 'name').map((c) => (
          <Table.Tr key={c.name}>
            <Table.Td ta="right">{c.symbol || c.name}</Table.Td>
            <Table.Td>
              <CryptoLookupTxLink address={c.address}>
                {c.address}
              </CryptoLookupTxLink>
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  )
})
