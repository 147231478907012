import { memo, type ReactNode } from 'react'

import { Anchor, type AnchorProps, Group } from '@mantine/core'
import type { Hex } from 'viem'

import { useListCryptoContracts } from '../../queries/crypto_contracts'
import { IconLookupTx } from '../Icons'

export const CryptoLookupTxLink = memo<
  AnchorProps & {
    address: Hex
    children?: ReactNode
  }
>(function CryptoLookupTxLink({ children, address, ...props }) {
  const {
    data: { contract_base_url },
  } = useListCryptoContracts()

  return (
    <Anchor
      target="_blank"
      href={`${contract_base_url}/search?q=${address}`}
      underline="never"
      {...props}
    >
      <Group gap="2px" align="center">
        {children}
        <IconLookupTx size="1.2em" title="lookup tx" />
      </Group>
    </Anchor>
  )
})
